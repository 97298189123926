/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import initToggle from './modules/toggle-container'
import mobileNavigation from './modules/mobile-navigation'
import toggleSub from './modules/toggle-sub-menu'
// import Swiper from 'swiper'
import Swiper from '../node_modules/swiper/dist/js/swiper.js'
import notifier from './vendor/notifier'
import debug from './modules/debug'
import initToggleRecaptcha from './modules/toggleReCaptcha.js'

// import fetchPosts from './modules/fetch-facebook'
/**
 * Ab geht die Reise
 */
domready(() => {
  if (document.getElementById('message-notifer')) {
    console.log(document.getElementById('message-notifer'))
    notifier.show(document.getElementById('message-notifer').getAttribute('data-message'), '', document.getElementById('message-notifer').getAttribute('data-status'), '', 4000)
  }
  mobileNavigation()
  toggleSub()
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  initToggle()
  debug()
  initToggleRecaptcha()
  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: true,
    pagination: '.meinunge-page'
  })

  // eslint-disable-next-line
  new Swiper('#vn-bilder', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: {
      delay: 6000
    },
    speed: 2000,
    loop: true,
    pagination: {
      el: '.vn-bilder-page',
      type: 'bullets',
      clickable: true
    }
  })

  // eslint-disable-next-line
  new Swiper('.slider-js', {
    effect: 'fade',
    slidesPerView: 1,
    autoplay: {
      delay: 6000
    },
    speed: 2000,
    paginationClickable: true,
    loop: true
  })

// eslint-disable-next-line
  new Swiper('.swiper-container.leistungs-swiper', {
    speed: 600,
    slidesPerView: 3,
    spaceBetween: 20,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 5000
    },
    navigation: {
      prevEl: '.swiper-button-prev.leistung-button-prev',
      nextEl: '.swiper-button-next.leistung-button-next'
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 20
      }
    }
  })

  // eslint-disable-next-line
  baguetteBox.run('.gallery')
// eslint-disable-next-line

// eslint-disable-next-line
  tail.DateTime('#datepicker', {
    locale: 'de',
    weekStart: 1,
    dateFormat: 'dd.mm.YYYY'
  })
})
