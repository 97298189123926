import $ from 'jquery'

export default function init () {
  var selectors = ['#nlUnsubMail', '#newsletterMail', '#infoFormName', '#anfrage input', '#terminemail']
  $.each(selectors, function (index, value) {
    $(document).on('focus', value, function (e) {
      $(this).closest('form').find('.g-recaptcha').removeClass('closed')
    })
  })
}
